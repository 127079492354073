import "./hike.css";

function Hike() {
  return (
    <>
      <div className="hikediv">
        <div className="hikesection">
          <div className="saleryhike">
            <h1 className="reviewh1">95% </h1>
            <p className="reviewp">Avg CTC Hike </p>
          </div>
          <div className="studentspleced">
            <h1 className="reviewh1"> 25000+</h1>
            <p className="reviewp">Students Placed </p>
          </div>
          <div className="job-assiatanace ">
            <h1 className="reviewh1">100%</h1>
            <p className="reviewp">Job Assurance</p>
          </div>
        </div>
      </div>
    </>
  );
}
export default Hike;
