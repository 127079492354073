import Accordion from "react-bootstrap/Accordion";
import "./Dsa.css";

function ProgramDsa() {
  return (
    <div className="stu">
      <h1 id="stu1">
        Program Structure - <span> DSA & System Design</span>{" "}
      </h1>
      <p id="stup">
        Here’s a structured syllabus for a Certification in Data
        Structures,Algorithms (DSA), and System Design:
      </p>
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            Module 1: Introduction to Data Structures and Algorithms
          </Accordion.Header>
          <Accordion.Body>
            <ul>
              <li>
                Introduction to DSA: Overview of data structures and their
                importance in algorithms.
              </li>
              <li>
                Algorithmic Complexity: Understanding Big O, Big Theta, and Big
                Omega notations.
              </li>
              <li>
                Time and Space Complexity Analysis: Focus on analyzing
                algorithms for efficiency.
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="1">
          <Accordion.Header>
            Module 2: Fundamental Data Structures
          </Accordion.Header>
          <Accordion.Body>
            <ul>
              <li className="points">Arrays and Linked Lists:</li>
              <ul>
                <li>Basics, types (Singly, Doubly, Circular).</li>
                <li>Insertion, deletion, traversal, and search operations.</li>
              </ul>
              <li className="points">Stacks and Queues:</li>
              <ul>
                <li>Concepts, implementations, and applications.</li>
                <li>Variants like circular queues and priority queues.</li>
              </ul>
              <li className="points">Hashing:</li>
              <ul>
                <li>
                  Hash tables, hash functions, collision handling (chaining,
                  open addressing).
                </li>
              </ul>
              <li className="points">Strings:</li>
              <ul>
                <li>
                  String manipulations and pattern matching algorithms (KMP,
                  Rabin-Karp).
                </li>
              </ul>
            </ul>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="2">
          <Accordion.Header>
            Module 3: Advanced Data Structures
          </Accordion.Header>
          <Accordion.Body>
            <ul>
              <li className="points">Trees:</li>
              <ul>
                <li>
                  Binary trees, binary search trees (BSTs), AVL trees, red-black
                  trees, B-trees.
                </li>
                <li>Tree traversals (in-order, pre-order, post-order).</li>
              </ul>
              <li className="points">Heaps:</li>
              <ul>
                <li>Binary heaps, min-heaps, max-heaps.</li>
                <li>Priority queues, heapsort algorithm.</li>
              </ul>
              <li className="points">Graphs:</li>
              <ul>
                <li>
                  Graph representations (adjacency matrix, adjacency list).
                </li>
                <li>
                  Graph traversal (BFS, DFS), shortest path algorithms
                  (Dijkstra’s, Bellman-Ford, Floyd-Warshall), minimum spanning
                  trees (Kruskal’s, Prim’s).
                </li>
              </ul>
            </ul>
          </Accordion.Body>
        </Accordion.Item>

        {/* New Modules */}
        <Accordion.Item eventKey="3">
          <Accordion.Header>
            Module 4: Sorting and Searching Algorithms
          </Accordion.Header>
          <Accordion.Body>
            <ul>
              <li className="points">Sorting:</li>
              <ul>
                <li>
                  Advanced sorting algorithms: quicksort, mergesort, heapsort,
                  radix sort.
                </li>
              </ul>
              <li className="points">Searching:</li>
              <ul>
                <li>Binary search, search trees, interpolation search.</li>
              </ul>
              <li className="points">Divide and Conquer:</li>
              <ul>
                <li>
                  Understanding divide and conquer techniques with examples
                  (merge sort, quicksort).
                </li>
              </ul>
            </ul>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="4">
          <Accordion.Header>
            Module 5: Dynamic Programming and Greedy Algorithms
          </Accordion.Header>
          <Accordion.Body>
            <ul>
              <li className="points">Dynamic Programming:</li>
              <ul>
                <li>Principles of DP (memoization and tabulation).</li>
                <li>
                  Solving problems like longest common subsequence, 0/1
                  knapsack, and matrix chain multiplication.
                </li>
              </ul>
              <li className="points">Greedy Algorithms:</li>
              <ul>
                <li>Introduction to greedy methods and its applications.</li>
                <li>
                  Problems like Huffman coding, coin change problem, and
                  activity selection.
                </li>
              </ul>
            </ul>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="5">
          <Accordion.Header>
            Module 6: Problem-Solving Techniques
          </Accordion.Header>
          <Accordion.Body>
            <ul>
              <li className="points">Backtracking and Recursion:</li>
              <ul>
                <li>
                  Solving problems like N-Queens, Sudoku, and graph coloring.
                </li>
              </ul>
              <li className="points">Graph Algorithms:</li>
              <ul>
                <li>
                  Network flow, bipartite graphs, Eulerian and Hamiltonian
                  circuits.
                </li>
              </ul>
              <li className="points">Complexity Optimization:</li>
              <ul>
                <li>
                  Techniques to optimize time and space complexity in
                  problem-solving.
                </li>
              </ul>
            </ul>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="6">
          <Accordion.Header>
            Module 7: System Design Fundamentals
          </Accordion.Header>
          <Accordion.Body>
            <ul>
              <li>
                Introduction to System Design: Importance, key concepts
                (scalability, reliability, maintainability).
              </li>
              <li>
                Networking Basics: Overview of networking principles, protocols
                (TCP/IP), and load balancing.
              </li>
              <li className="points">Designing Scalable Systems:</li>
              <ul>
                <li>Vertical vs. horizontal scaling.</li>
                <li>Database sharding and replication.</li>
              </ul>
              <li className="points">Data Storage:</li>
              <ul>
                <li>SQL vs. NoSQL databases, CAP theorem.</li>
                <li>Caching mechanisms (Redis, Memcached).</li>
              </ul>
              <li className="points">Designing Distributed Systems:</li>
              <ul>
                <li>Microservices architecture.</li>
                <li>Consistency, availability, partition tolerance (CAP).</li>
              </ul>
            </ul>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="7">
          <Accordion.Header>Module 8: Design Patterns</Accordion.Header>
          <Accordion.Body>
            <ul>
              <li className="points">Introduction to Design Patterns:</li>
              <ul>
                <li>Creational, Structural, and Behavioral patterns.</li>
              </ul>
              <li className="points">Common Design Patterns:</li>
              <ul>
                <li>
                  Singleton, Factory, Observer, Adapter, Strategy, and MVC
                  (Model-View-Controller).
                </li>
              </ul>
              <li className="points">Best Practices:</li>
              <ul>
                <li>
                  How to implement patterns effectively in real-world scenarios.
                </li>
              </ul>
            </ul>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="8">
          <Accordion.Header>
            Module 9: Advanced System Design Concepts
          </Accordion.Header>
          <Accordion.Body>
            <ul>
              <li className="points">Message Queues:</li>
              <ul>
                <li>Understanding message brokers (Kafka, RabbitMQ).</li>
                <li>
                  Event-driven architecture and asynchronous communication.
                </li>
              </ul>
              <li className="points">Distributed Systems:</li>
              <ul>
                <li>Consensus algorithms (Paxos, Raft), leader election.</li>
              </ul>
              <li className="points">Scaling Web Applications:</li>
              <ul>
                <li>
                  Load balancing strategies, CDN integration, auto-scaling.
                </li>
              </ul>
              <li className="points">Security Considerations:</li>
              <ul>
                <li>
                  Securing data, encryption, secure authentication (OAuth2,
                  JWT).
                </li>
              </ul>
            </ul>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="9">
          <Accordion.Header>Module 10: Capstone Project</Accordion.Header>
          <Accordion.Body>
            <ul>
              <li className="points">Practical Implementation:</li>
              <ul>
                <li>
                  Apply knowledge of DSA and system design principles to build a
                  scalable, efficient, and robust real-world application.
                </li>
              </ul>
              <li className="points">Evaluation:</li>
              <ul>
                <li>Code review, performance analysis, scalability testing.</li>
              </ul>
            </ul>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="10">
          <Accordion.Header>
            Module 11: Interview Preparation & Mock Sessions
          </Accordion.Header>
          <Accordion.Body>
            <ul>
              <li>
                DSA Practice Problems: Focus on solving competitive-level
                problems.
              </li>
              <li>
                System Design Mock Interviews: Mock interviews focused on
                solving system design questions in real-time scenarios.
              </li>
              <li>
                Tips and Techniques: How to approach technical and system design
                interviews.
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>

        {/* Certification Requirements */}
        <Accordion.Item eventKey="11">
          <Accordion.Header>Certification Requirements</Accordion.Header>
          <Accordion.Body>
            <ul>
              <li>
                Assessments: Complete quizzes, assignments, and projects for
                each module.
              </li>
              <li>
                Capstone Project: Final real-world project assessed by mentors.
              </li>
              <li>
                Certification Exam: Final exam covering DSA, algorithms, and
                system design concepts.
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
}

export default ProgramDsa;
