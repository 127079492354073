import "./Pricing.css";

function Pricing() {
  return (
    <>
      <div className="pricing">
        <div className="pricingdiv online-pricing">
          <h4 className="pricingdivh4">Fees Structure</h4>
          <div className="pricing-option">
            <h4>Actual - ₹ 169,000</h4>
            <h4>Online - ₹ 69,000/- INR only</h4>
          </div>
          <p className="emitext">
            {" "}
            Enjoy hassle-free learning with EMIs starting at ₹4,600 per month —
            that's easier on your budget than groceries!
          </p>
          <a href="/">
            <button className="apply-button">Apply Now</button>
          </a>
        </div>
      </div>
      <div className="pricing">
        <div className="pricingdiv online-pricing">
          <h4 className="pricingdivh4">Fees Structure</h4>
          <div className="pricing-option">
            <h4>Actual - ₹ 1,99,999</h4>
            <h4>Classroom (Offline) - ₹ 99,999/- INR only</h4>
          </div>

          <p className="emitext">
            {" "}
            Enjoy hassle-free learning with EMIs starting at ₹6,666 per month —
            that's easier on your budget than groceries!
          </p>
          <a href="/">
            <button className="apply-button">Apply Now</button>
          </a>
        </div>
      </div>
    </>
  );
}

export default Pricing;
