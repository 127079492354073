import React from "react";
import "./Coursecard.css";
import { NavLink } from "react-router-dom";

import Dsa from "../../Assets/Dsa.png";

function CourseCard() {
  return (
    <>
      <h1 className="skillbuild-title">
        Skill-Building Programs for Career Success
      </h1>
      <div className="coursecon">
        <div className="dsa-course-card">
          <img src={Dsa} alt="Academy Icon" />
          <div className="dsa-content">
            <span className="dsa-nsdc-cert">ISO TSN Certified</span>
            <h3>Master Certification in DSA</h3>
            <div className="dsa-info">
              <div>
                <i className="fas fa-briefcase"></i>100% Job Assistance
              </div>
              <div>
                <i className="fas fa-clock"></i> Duration: 6-8 months
              </div>
            </div>
            <div className="dsa-info">
              <div>
                <i className="fas fa-money-bill-wave"></i>11LPA + Average
              </div>
              <div>
                <i className="fas fa-project-diagram"></i> Real-World Projects
              </div>
            </div>
            <div className="dsa-info">
              <div>
                <i className="fas fa-user-tie"></i>Mentors from Top MNC
              </div>
              <div>
                <i className="fas fa-comments"></i> 24/7 Learning Support
              </div>
            </div>
            <span className="dsa-online-badge">Online + Offline</span>
            <NavLink to="/Course">
              <button>GO TO PROGRAM</button>
            </NavLink>
            <a href="https://bluink360.com/pdfdata/Bluink360_DSA_Course.pdf">
              BROCHURE <i className="fas fa-download"></i>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default CourseCard;
